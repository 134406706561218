import React from 'react';
import PropTypes from 'prop-types';

const YoutubeSection = ({ height, width, youtubeVideoUrl }) => (
  <div className="youtube-video-container">
    <div className="popmenu-youtube-video">
      <iframe title="popmenu-embed-youtube-video" width={width} height={height} src={youtubeVideoUrl} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; fullscreen; gyroscope; picture-in-picture" />
    </div>
  </div>
);

YoutubeSection.defaultProps = {
  height: '315',
  width: '560',
};

YoutubeSection.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  youtubeVideoUrl: PropTypes.string.isRequired,
};

export default YoutubeSection;
